import _export from "../internals/export";
import _objectSetPrototypeOf from "../internals/object-set-prototype-of";
var $ = _export;
var setPrototypeOf = _objectSetPrototypeOf;

// `Object.setPrototypeOf` method
// https://tc39.es/ecma262/#sec-object.setprototypeof
$({
  target: "Object",
  stat: true
}, {
  setPrototypeOf: setPrototypeOf
});
export default {};